import  {React, useRef, useImperativeHandle, forwardRef, useState } from 'react';
import {Power2, TweenMax} from 'gsap';

import './style/background.css';

const Background = forwardRef((props,ref) => {
    const [isAnim,setIsAnim] = useState(false);
    const row = 5;
    const col = 10;
    const colors = ['rgba(242,228,211,0.3)','rgba(232,184,241,0.3)','rgba(184,241,215,0.3)','rgba(241,184,208,0.3)']
    const cardRef = useRef([]);
    const bgRef = useRef();


    const animate = () => {
        const nBgColor = colors[Math.floor(Math.random()*colors.length)];
        TweenMax.to(bgRef.current.childNodes, {
            duration: 0.8,
            scale: 0,
            rotation: 360,
            yoyo: true,
            repeat: 1,
            ease: Power2.in,
            stagger: {
            grid: [row,col],
            from: "start",
            amount: 0.5
            },
            onRepeat: function(){
                this._targets.forEach((el)=>{
                    el.style.backgroundColor = nBgColor;
                })
            },
            onComplete: ()=>{
                setIsAnim(false);
            }
        })
    };


    useImperativeHandle(ref, ()=>({
        animate: () =>{
            if(!isAnim){
                setIsAnim(true);
                animate();
            }
        }
    }))


    const drawBg = () =>{
        const elements=[];
        for(let i = 0; i< row*col; i++){
            elements.push(<div key={i} className='panel' style={{width: 100/col+'%', height: 100/row+'%', backgroundColor: `rgba(242,228,211,0.3)`}}></div>)
        }
        cardRef.current=elements;
        return elements
    }


    return(
    <div ref={bgRef} className ='Background'>
        {drawBg()}
    </div>
    )
})

export default Background;