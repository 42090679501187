import {React, useState} from 'react'
import './style/foo-button.css'
function FooButton(props){
    const [copy, setCopy] = useState("Polas 🍻");
    const options = [
        "por Empanadas 🥟",
        "pa' Marte 🤖",
        "por Cafe ☕",
        "por Galletas 🍪",
        "por Sushi 🍣",
        "pal Rio 🏞️",
        "pal Pacifico 🌊",
        "pa la playa 🏖️",
        "a rumbiar 💃🕺",
        "a trabajar 👨‍💻",
        "a rankear 🕹️"
    ]

    const handleClick = () =>{
        setCopy(options[Math.floor(Math.random()*options.length)])
        props.onClick();
    }
    return(
        <button className="FooButton" onClick={handleClick}>Vamos <span>{copy}</span></button>
    )
}

export default FooButton;