import './style/name-label.css'
function NameLabel(){
return(
    <div className="NameLabel">
        <span>soy&nbsp;</span>
        <span className="label-wrapper">
            <div className="mask">
                <h1 className="label name">
                    Juan C. Ramon
                </h1>
                <h1 className="label title">
                    Interactive Developer
                </h1>
            </div>
        </span>
    </div>
)
}

export default NameLabel;