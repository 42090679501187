import  {React, createRef} from 'react';
import placeImage from './images/san-cipriano.jpeg';
import './App.css';

import Background from './components/Background';
import FooCamp from './components/FooCamp';
import FooButton from './components/FooButton';
import NameLabel from './components/NameLabel';

function App() {
  const bgRef = createRef();
  const handleButtonClick = () =>{
    bgRef.current.animate();
  }

  return (
    <div className="App">
      <Background ref={bgRef} />

      <a className="plug" href="https://juanramon.dev" target="_blank" rel="noreferrer">juanramon.dev</a>
      <section className="description">
        <article>
          <NameLabel />
          <h2>Mi interés en <FooCamp />:</h2>
          <p>
            Estoy interesado en participar en el <FooCamp /> para medir, afianzar y mejorar mis habilidades como desarrollador interactivo. Trabajo en la industria del advertizing por lo que mis proyectos suelen ser de corta duracion, poco mantenimiento y tiempos de desarrollo super cortos, por lo que implementar buenas practicas no siempre es facil. Me gustaria aprender mas sobre estandares, workflows y metodologias en proyectos con mas tiempo de vida.
          </p>
          <FooButton onClick={handleButtonClick}/>
        </article>
        <aside>
          <img src={placeImage} className="App-logo" alt="favorite place" />
        </aside>
      </section>
    </div>
  );
};

export default App;
